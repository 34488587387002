import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV
const baseUrlApi = process.env.VUE_APP_MICROSERVICE_API_INTRANET

export default class PacientesService {
  listarPacientes (params) {
    return http.get(`${baseUrl}/pacientes/search`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  listarTiposId () {
    return http.get(`${baseUrl}/pacientes/tipos-id`, {
      headers: {
        loading: true
      }
    })
  }

  tiposIdentificacion () {
    return http.get(`${baseUrl}/tipos-identificacion/obtener-tipos-identificacion`, {
      headers: {
        loading: true
      }
    })
  }

  listarHistoriaClinica (params) {
    const filtros = {
      cedula: params.filtros.cc,
      tipo_num_documento: params.filtros.type_doc
    }
    // return http.get(`${baseUrlApi}/pacientes/historia-clinica`, {
      return http.get(`${baseUrlApi}/ordenes/historiaClinica`, {
      params: filtros,
      headers: {
        loading: true
      }
    })
  }

  actualizarPaciente (body, id) {
    return http.put(`${baseUrl}/pacientes/actualizar-paciente/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  paginate (params) {
    return http.get(`${baseUrl}/pacientes/paginate`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  listPacienteSyncPend (params) {
    return http.get(`${baseUrl}/pacientes/paginate-sync-pend`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  listPacienteSync (params) {
    return http.get(`${baseUrl}/pacientes/paginate-sync-hist`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  sync (data) {
    return http.post(`${baseUrlApi}/pacientes/sync`, data, {
      headers: {
        loading: true
      }
    })
  }

  getPac (id) {
    return http.get(`${baseUrl}/pacientes/find/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  crearPac (data) {
    return http.post(`${baseUrl}/pacientes`, data, {
      headers: {
        loading: true
      }
    })
  }

  editarPac (data, id) {
    return http.put(`${baseUrl}/pacientes/actualizar-paciente/${id}`, data, {
      headers: {
        loading: true
      }
    })
  }

  eliminarPac (id) {
    return http.delete(`${baseUrl}/pacientes/${id}`, {
      headers: {
        loading: true
      }
    })
  }

  getCiudades (nombre = '') {
    return http.get(`${baseUrl}/localizacion/search/${nombre}`, {
      headers: {
        loading: true
      }
    })
  }

  getDptos (nombre = '') {
    return http.get(`${baseUrl}/localizacion/listar-departamentos/${nombre}`, {
      headers: {
        loading: true
      }
    })
  }

  getEntidades = (nombre = '') => {
    return http.get(`${baseUrl}/ov-entidad/search/${nombre}`, {
      headers: {
        loading: true
      }
    })
  }

  getClientes = (nombre = '') => {
    return http.get(`${baseUrl}/ov-cliente/search/${nombre}`, {
      headers: {
        loading: true
      }
    })
  }
}
